import './header.css';

import React, {useEffect} from 'react';
import {Button, Col, Container, Nav, Navbar, Row} from 'react-bootstrap';
import {FaBars, FaTimes} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router-dom';

import logo from '../images/xfun-logo.svg';
import * as route from '../pages/routesData';

const Header: React.FC = () => {
  const location = useLocation();

  const aboutLink =
    location.pathname === route.INDEX ? (
      <Nav.Link title="About XFUN Wallet" href={'#about'} className="header-text">
        About XFUN Wallet
      </Nav.Link>
    ) : (
      <Nav.Link as={Link} title="About XFUN Wallet" to={route.INDEX + '#about'} className="header-text">
        About XFUN Wallet
      </Nav.Link>
    );

  const featuresLink =
    location.pathname === route.INDEX ? (
      <Nav.Link title="Key features" href={'#features'} className="header-text">
        Key features
      </Nav.Link>
    ) : (
      <Nav.Link as={Link} title="Key features" to={route.INDEX + '#features'} className="header-text">
        Key features
      </Nav.Link>
    );

  const downloadLink =
          location.pathname !== route.WALLET_CONNECT ? (
          <div className="d-flex col justify-content-center">
              <Button
                      className="header-button rounded-pill"
                      onClick={(event) => scrollToElement('#download', event)}>
                  DOWNLOAD
              </Button>
          </div> ) : null;

  useEffect(() => {
    if (location.hash) {
      const elemId = location.hash.replace('#', '');
      const elem = document.getElementById(elemId);
      if (elem) {
        elem.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [location]);

  const scrollToElement = (elId: any, event: any) => {
    event.preventDefault();
    const elem = document.getElementById(elId.substring(1));
    if (elem) {
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <header>
      {location.pathname === '/app-xfun/rubic.html' ? (
        ''
      ) : (
        <Container fluid>
          <Row>
            <Col>
              <Navbar expand="md" collapseOnSelect onSelect={scrollToElement}>
                <Link title="Home" to={route.INDEX}>
                  <img src={logo}></img>
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbarToggler">
                  <FaBars className="navbarBars" />
                  <FaTimes className="navbarTimes" />
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav>
                    {aboutLink}
                    {featuresLink}
                    {downloadLink}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Col>
          </Row>
        </Container>
      )}
    </header>
  );
};

export default Header;
