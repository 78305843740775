import React from 'react';
import {Col, Row} from 'react-bootstrap';

import ico_x from '../../images/ico_x.svg';

const KeyFeatures: React.FC = () => {
  return (
    <div className="mainWrapper">
      <h2 id="features">Key features</h2>
      <div className="normalText keyFeaturesText">
        XFUN Wallet provides useful features to facilitate fast, smooth and secure transactions on various blockchains.
      </div>
      <Row>
        <Col xs={12} sm={6}>
          <div className="keyFeaturesItem">
            <img src={ico_x}></img>
            <div>FUN and XFUN Exchange</div>
          </div>
        </Col>
        <Col xs={12} sm={6}>
          <div className="keyFeaturesItem">
            <img src={ico_x}></img>ONE-TOUCH Registration
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <div className="keyFeaturesItem">
            <img src={ico_x}></img>WalletConnect Support
          </div>
        </Col>
        <Col xs={12} sm={6}>
          <div className="keyFeaturesItem">
            <img src={ico_x}></img>Works with any ERC-20 Tokens
          </div>
        </Col>
      </Row>
      <div className="normalText keyFeaturesText">
        Access Web3 dApps powered by Ethereum or Polygon smart contracts with XFUN Wallet and discover the world of
        decentralised finance, games, and much more!
      </div>
    </div>
  );
};

export default KeyFeatures;
