import './terms.css';

import React from 'react';
import {Col, Row} from 'react-bootstrap';

const Terms: React.FC = () => {
  return (
    <div className="mainTerms">
      <Row className="mainTermsWrapper">
        <Col xs={1} md={1} lg={2}>
          {' '}
        </Col>
        <Col xs={10} md={10} lg={8}>
          <h1>XFUN Terms & Conditions</h1>

          <div className="normalText">
            <p>
              The terms of use outlined here apply to the services and products offered by Funtech Ltd
              (&quot;Company&quot;, &quot;we&quot;, &quot;us&quot;, &quot;our&quot;), including XFUN Wallet
              (&quot;Service&quot;, &quot;Wallet&quot;) and all visitors, users and others (&quot;You&quot;,
              &quot;user&quot;).
            </p>
          </div>

          <div className="normalText">
            <h2>1. GENERAL CONDITIONS</h2>
            <p>
              Please read through these Terms of Use carefully before using any Funtech Ltd Website, Services, or other
              Products as mentioned in the above section. By using Funtech Ltd Website, Services, or other Products or
              by clicking to agree or accept the Terms of Use outlined herein, you are agreeing to accept these binding
              Terms of Use. This also applies to our Privacy Policy, Cookies Policy, KYC/AML policy and other Related
              Policies. If you do not agree to these Terms of Use or Related Policies, you should not access or
              otherwise use the Website, Services or any other Products.
            </p>
            <h3>1.1. In order to be able to use our Services You warrant and represent that you:</h3>
            <p>a) are at least 18 years old or of other legal age, according to your relevant jurisdiction;</p>
            <p>
              b) have sufficient experience, knowledge and understanding of the working principles of our Wallet, fully
              understand the associated risks access and use the Wallet at your own risk;
            </p>
            <p>
              c) have full power and authority to enter into this legal relationship and by doing so will not violate
              any other legal relationships;
            </p>
            <p>
              d) should be aware that your country of residence may impose certain unique restrictions to the Services
              provided by the Company, and you are responsible for complying with rules and regulations as applicable to
              your activities with the Company;
            </p>
            <p>
              e) guarantee that your crypto assets, which you transfer to the Wallet are not encumbered, not in
              contention, or under seizure, and that neither exists any rights of third parties to your crypto assets.
              For the purposes hereof “Crypto assets” shall mean such type of assets which can only and exclusively be
              transmitted by means of blockchain technology, including but not limited to digital coins and digital
              tokens and any other type of digital mediums of exchange, such as BTC, ETH, etc., to the full and absolute
              exempt of the securities of any kind;e) will not use our Services or will immediately cease using those if
              any applicable law in your country prohibits or will prohibit you at any time from doing so;
            </p>
            <p>
              f) will not use our Services or will immediately cease using those if you are a resident or become a
              resident at any time of the state or region (in accordance with such state or region definition of
              residency), where the crypto assets Transactions you are going to execute are prohibited or require
              special approval, permit and/or authorization of any kind, which the Company and/or You have not obtained
              in this state or region; AND
            </p>
            <p>g) have not previously been suspended or removed from our Services.</p>
            <h3>
              1.2. You further represent, agree and warrant, that you will not violate any law, contract, third-party
              right or commit a tort by accessing or using the Wallet, and that you are solely responsible for your
              actions and/or inactions while using our Wallet. Without prejudice to the foregoing, you represent, agree
              and warrant, that YOU WILL NOT:
            </h3>
            <p>
              a) Use the Wallet to pay for, support or otherwise engage in any illegal gambling activities;
              money-laundering; fraud; or terrorist activities; or any other illegal activities;
            </p>
            <p>b) Use or attempt to use another User account without authorization, given knowingly and voluntarily;</p>
            <p>c) Provide false, inaccurate, or misleading information;</p>
            <p>
              d) Use the Wallet in any manner that could interfere with, disrupt, negatively affect or inhibit other
              users from using our Wallet with full functionality, or that could damage, disable, overburden or impair
              the functioning of the Wallet in any manner;
            </p>
            <p>
              e) Use any robot, spider, crawler, scraper or other automated means or interface not provided by Us to
              access the Wallet or to extract data;
            </p>
            <p>f) Attempt to access any service or area of our Wallet that you are not authorized to access;</p>
            <p>
              g) Develop any third-party applications that interact with our Wallet without our prior written consent;
              AND
            </p>
            <p>h) Encourage or induce any third party to engage in any</p>
            <h3>
              1.3. YOU INDEMNIFY AND HOLD Funtech Ltd HARMLESS AGAINST ANY CLAIMS, DEMANDS AND DAMAGES, WHETHER DIRECT,
              INDIRECT, CONSEQUENTIAL OR SPECIAL, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS
              OF USE, LOSS OF PROFITS OR LOSS OF DATA OR LOSS OF ASSETS, WHETHER IN AN ACTION IN CONTRACT, TORT
              (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ORIGINATED FROM OR IN ANY WAY CONNECTED WITH
              INVALIDITY OR BREACH OF ANY OF THE WARRANTIES, REPRESENTATIONS AND COVENANTS OF THIS SECTION AND THE
              ENTIRE TERMS.
            </h3>
            <p>
              YOU UNDERSTAND THAT Funtech Ltd DOES NOT HAVE ACCESS TO YOUR FUNDS, IN THE ABSENCE OF YOUR PASSWORD. WHEN
              A WALLET IS INITIALLY GENERATED, IT IS IMMEDIATELY ENCRYPTED WITH THE USER’S PASSWORD, AND PLACED ON KMS
              (KEY MANAGEMENT SYSTEM). AT NO POINT DOES XFUN STORE THE USER’S PASSWORD, SO EVEN IF WE WANTED TO, WE
              CANNOT GAIN ACCESS TO THE UNENCRYPTED PRIVATE KEY.
            </p>
            <h3>
              1.4. Changes to the Terms of Use. We may revise and update these Terms of Use at our sole discretion, and
              any changes made are effective immediately. You are expected to check this page from time to time to
              monitor for any changes, and any changes made will be binding to you and your use of Funtech Ltd,
              Services, and any other Products.
            </h3>
          </div>

          <div className="normalText">
            <h2>2. LIMITATIONS OF SUPPORTED CRYPTO ASSETS</h2>
            <h3>
              2.1. Our Wallet allows Users to remit crypto assets to XFUN Account from external third-party services and
              vice versa except to certain limitations, which may be updated from time to time.
            </h3>

            <p>
              YOU INDEMNIFY AND HOLD Funtech Ltd HARMLESS AGAINST ANY CLAIMS, DEMANDS AND DAMAGES, WHETHER DIRECT,
              INDIRECT, CONSEQUENTIAL OR SPECIAL, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS
              OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED
              TO NEGLIGENCE) OR OTHERWISE, ORIGINATED FROM OR IN ANY WAY CONNECTED WITH YOUR DEPOSIT/WITHDRAWAL
              TRANSACTIONS REQUESTS IN VIOLATION OF SETTLED LIMITATIONS.
            </p>

            <p>
              For the purposes thereof the &quot;Third-party service&quot; shall mean any platform or network in which
              crypto assets belong to you or where you are the beneficial owner of crypto assets; and this platform is
              maintained by a third party outside of the Services; including, but not limited to third-party accounts.
            </p>
          </div>
          <div className="normalText">
            <h2>3. CONSENTS AND AUTHORIZATIONS</h2>
            <h3>
              3.1. In order to protect your crypto assets from unauthorized access, use, or spending we use a variety of
              physical and technical measures designed to protect our systems and your crypto assets. To be more
              definite we store encrypted crypto wallets.
            </h3>
            <h3>
              3.2. By accepting these Terms you also agree to our General Terms and Conditions and our Privacy Policy.
              Our Privacy Policy is accessible at privacy notice and describes how we collect, use, and share your
              personal information.
            </h3>
          </div>

          <div className="normalText">
            <h2>4. WALLET</h2>
            <h3>
              4.1. When creating an XFUN Wallet account, the Wallet generates and stores a private and public
              cryptographic key pair that you can use to make transactions with crypto assets. The public key can be
              shared to complete transactions. The private key uniquely matches the wallet address and is only used by
              the Wallet to authorize transactions to and from the Wallet.
            </h3>
            <h3>
              4.2. The balance of crypto assets displayed by the Wallet in the user interface of your application is
              accurate and the balance represents the current amount of crypto assets you are able to transfer or
              exchange.
            </h3>
            <h3>
              4.3. The optional Account Registration feature stores your registration data on your device for the
              purpose of reusing this data to register with other compatible dApps when you choose to do so. The Wallet
              does not store or send this data without your permission. XFUN is not responsible for the security of the
              data of which you authorize its transfer to any third-party service.
            </h3>
            <h3>
              4.4 We reserve the right to limit the scope of our Services to users. Limitation of our Services may
              depend on the established security level of the account, for instance, and the following criteria may be
              considered: verification of e-mail, social network authorization etc. Please note that the restrictions
              mentioned herein are for illustrative purposes only. We reserve the right to limit the scope of our
              Services at our sole discretion and without prior notification to you.
            </h3>
            <h3>
              4.5 In case of Your breach of the Terms, or any other event as We may deem necessary, including without
              limitation the Force Majeure Event; maintenance work; inability of the third service provider to provide
              you services; your request of account cancellation; market disruption; inability to conform our
              requirements; lack of verification pending litigation, investigation, or government proceeding related to
              You or your wallet account; and/or in case we perceive a heightened risk of legal or regulatory
              non-compliance associated with your account activity We may, in our sole discretion and without liability
              to you, with or without prior notice:
            </h3>
            <p>a) suspend your access to all or a portion of our Services; or‍</p>
            <p>
              b) delete or deactivate your XFUN Wallet Account and all related information and files in such account.
            </p>
          </div>

          <div className="normalText">
            <h2>5. TRANSACTIONS</h2>
            <h3>
              5.1. The following Transactions are available to be performed by User via XFUN Wallet with their crypto
              assets:
            </h3>
            <p>
              a) &quot;SEND&quot;, a remittance of the crypto asset, owned by one XFUN Wallet Account User, to another
              XFUN Wallet Account User. The Transfer is executed solely and exclusively within the Wallet and with
              crypto assets deposited to respective Users’ XFUN Wallet Accounts.
            </p>
            <p>
              b) &quot;RECEIVE&quot;, a remittance of crypto assets to XFUN Wallet Account from external third-party
              service.
            </p>
            <p>c) “EXCHANGE”, an exchange of FUN to XFUN tokens or vice versa.</p>
            <h3>5.2. Crypto assets are not refundable.</h3>
            <h3>
              5.3. Transactions that failed due to any technical issue, extreme network conditions or any other cause on
              the third-party service’s side will not be automatically rolled back to your originating address for
              technical and security reasons. YOUR WALLET WILL NOT BE REFUNDED IN SUCH CASES.
            </h3>
          </div>

          <div className="normalText">
            <h2>6. SPECIAL CONDITIONS</h2>
            <h3>
              6.1. You may be charged fees by the third-party service you use to remit your XFUN Wallet Account. XFUN is
              not responsible for any third-party services&apos; fees. You are solely responsible for your use of the
              third-party service, and you agree to comply with all terms and conditions applicable to any third-party
              service.
            </h3>
            <h3>
              6.2. It is the user&apos;s responsibility to decide whether any taxes apply to the Transactions you
              perform through the XFUN Services, and to withhold, report and remit the relevant funds to the appropriate
              tax authorities. You agree that the XFUN Wallet is not responsible for determining whether any taxes apply
              to your transfers or for collecting, reporting, withholding or remitting any taxes arising from any
              Transactions and does not act as your tax agent.
            </h3>
          </div>

          <div className="normalText">
            <h2>7. INDEMNIFICATIONS</h2>
            <h3>
              7.1. YOU AGREE TO INDEMNIFY AND HOLD Funtech Ltd HARMLESS AGAINST ANY CLAIMS, DEMANDS AND DAMAGES, WHETHER
              DIRECT, INDIRECT, CONSEQUENTIAL OR SPECIAL, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED
              TO, LOSS OF USE, LOSS OF PROFITS, LOSS OF DATA OR LOSS OF ASSETS, INCLUDING TOTAL ONE, WHETHER IN AN
              ACTION, IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ORIGINATED FROM OR IN
              ANY WAY CONNECTED WITH:
            </h3>
            <p>
              a) your use of XFUN Wallet, including, but not limited to those arising from your personal errors and
              misbehaviour such as forgotten passwords, incorrectly constructed transactions, loss of your accesses etc.
            </p>
            <p>
              b) any risks associated with utilizing any crypto assets blockchain network, including, but not limited to
              the risk of unknown vulnerabilities in or unanticipated changes to any network protocol;
            </p>
            <p>
              c) any communication failures, disruptions, errors, distortions or delays you may experience when using
              the Wallet, howsoever caused;
            </p>
            <p>
              d) software failures, protocol changes by third-party providers, internet outages, Force Majeure event or
              other disasters including third-party DDOS attacks, scheduled or unscheduled maintenance, or other causes
              either within or outside our control, any malfunction, unintended function, unexpected functioning of or
              attack on the Blockchain protocol that may cause the Wallet to malfunction or function in an unexpected or
              unintended manner. For the purposes hereof &quot;Force Majeure Event&quot; shall be understood as any
              event beyond XFUN Wallet&apos;s reasonable control, including but not limited to the flood, extraordinary
              weather conditions, earthquake, or other act of God, fire, war, insurrection, riot, labour dispute,
              accident, action of government, suspension of bank accounts of any kind, extraordinary leaps of the course
              of crypto asset, communications, network or power failure, or equipment or software malfunction or any
              other cause beyond XFUN Wallet&apos;s reasonable control;
            </p>
            <p>
              e) technical, typographical, or photographic errors in information and materials appearing on the Website
              or related applications;
            </p>
            <h3>
              7.2. The Service developed by us is provided &quot;as is&quot;. XFUN makes no warranties, expressed or
              implied, and hereby disclaims and negates all other warranties, including without limitation, implied
              warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of
              intellectual property or other violation of rights. Further, XFUN does not warrant or make any
              representations concerning the accuracy, likely results, or reliability of the use of the materials or
              otherwise relating to such materials or on any websites or apps linked to XFUN.
            </h3>
          </div>

          <div className="normalText">
            <h2>8. GOVERNING LAW</h2>
            <p>
              These Terms and any dispute between the parties arising therefrom will be governed by the laws of
              Gibraltar, without giving effect to any conflict of laws principles that may provide for the application
              of the law of another jurisdiction.
            </p>
          </div>

          <div className="normalText">
            <h2>9. SEVERABILITY CLAUSE</h2>
            <p>
              Should one or several provisions of these Terms be or become invalid or prove to be unenforceable, this
              shall not affect the validity of the other provisions.
            </p>
            <p>
              In this case, the contracting Parties shall replace the invalid or unenforceable provision with another
              provision that comes closest to the economically intended purpose. The same applies in the case of a
              regulatory gap
            </p>
          </div>

          <div className="normalText">
            <h2>10. MISCELLANEOUS</h2>
            <h3>
              10.1. In the event of any conflict between these Terms and any other agreement you may have with XFUN, the
              terms of that other agreement will prevail only if these Terms are specifically identified and declared to
              be overridden by such other agreement.
            </h3>
            <h3>
              10.2. We reserve the right to alter, amend or modify these Terms from time to time, at our sole
              discretion.
            </h3>
          </div>

          <div className="normalText">
            <p>© 2022 XFUN - operated by Funtech Ltd. All rights reserved.</p>
          </div>
        </Col>
        <Col xs={1} md={1} lg={2}>
          {' '}
        </Col>
      </Row>
    </div>
  );
};

export default Terms;
