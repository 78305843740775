import React from 'react';
import {Col, Row} from 'react-bootstrap';

import logo from '../../images/xfun-logo.svg';

const About: React.FC = () => {
  return (
    <div>
      <h1 id="about">XFUN Wallet</h1>
      <div className="subTitle"> A secure, non-custodial wallet for FUN, XFUN, other ERC-20 tokens, and Bitcoin</div>
      <Row className="whatIsXfunSection">
        <Col xs={12} md={6}>
          <div>
            <h2>What is XFUN Wallet?</h2>
          </div>
          <div className="normalText text-left">
            XFUN Wallet is a safe and easy-to-use cryptocurrency wallet for storing, sending, and receiving FUN, XFUN,
            and other ERC-20 tokens on the Ethereum and Polygon networks and also Bitcoin (BTC). Swapping FUN for XFUN
            tokens and vice versa is a unique feature of the XFUN Wallet. Because it is a non-custodial wallet, you have
            full control of your private key, and therefore your digital assets.
          </div>
        </Col>
        <Col xs={12} md={6}>
          <img src={logo}></img>
        </Col>
      </Row>
    </div>
  );
};

export default About;
