import './rubic.css';

import React, {useEffect} from 'react';

const Rubic: React.FC = () => {
  useEffect(() => {
    const lib = document.createElement('script');
    const script = document.createElement('script');

    lib.src = 'https://widgets.rubic.exchange/iframe/bundle.min.js';
    lib.async = true;
    script.src = '/rubic.js';
    script.async = true;

    document.body.appendChild(lib);
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(lib);
      document.body.removeChild(script);
    };
  }, []);

  return <div id="rubic-widget-root"></div>;
};

export default Rubic;
