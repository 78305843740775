import './App.css';

import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import * as route from '../src/pages/routesData';
import Footer from './components/Footer';
import Header from './components/Header';
import FAQ from './pages/FAQ';
import Home from './pages/Home';
import WalletConnectLP from './pages/Home/WalletConnectLP';
import NotFound from './pages/NotFound';
import Privacy from './pages/Privacy';
import Rubic from './pages/Rubic';
import Terms from './pages/Terms';
const App = () => (
  <BrowserRouter>
    <Header />
    <Routes>
      <Route path={route.INDEX} element={<Home />} />
      <Route path={route.WALLET_CONNECT} element={<WalletConnectLP />} />
      <Route path={route.ABOUT} element={<Home />} />
      <Route path={route.PRIVACY} element={<Privacy />} />
      <Route path={route.TERMS} element={<Terms />} />
      <Route path={route.FAQ} element={<FAQ />} />
      <Route path={route.RUBIC} element={<Rubic />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
    <Footer />
  </BrowserRouter>
);

export default App;
