import './privacy.css';

import React from 'react';
import {Col, Row} from 'react-bootstrap';

const Privacy: React.FC = () => {
  return (
    <div className="mainPrivacy">
      <Row className="mainPrivacyWrapper">
        <Col xs={1} md={1} lg={2} />
        <Col xs={10} md={10} lg={8}>
          <h1>XFUN PRIVACY POLICY</h1>
          <div className="normalText">
            <p>
              At XFUN, our approach to the privacy of individuals who use our websites, applications or services is
              complete clarity that begins with a privacy policy that uses simple language. We want our customers to
              have a complete understanding of how their information is collected, used and stored, without getting
              bogged down in confusing jargon and fine print.
            </p>

            <p>
              If you are new to XFUN, or have been with us for a while but have not consulted with the T&C in a long
              time, it is important to read this policy, making sure you understand everything. Please keep visiting
              from time to time to receive updates and keep the information fresh in your mind. ACCEPTANCE OF OUR
              PRIVACY POLICY By using our Site, Applications, Products or Services, you agree to the use, disclosure,
              and procedures outlined in this Privacy Policy.
            </p>
          </div>

          <h2>CHANGES TO THIS POLICY</h2>
          <div className="normalText">
            <p>
              We may update this policy from time to time, so please review it frequently. If any material changes are
              made to this Privacy Policy,
            </p>
          </div>

          <h2>ABOUT US</h2>
          <div className="normalText">
            <p>
              References in this Privacy Policy to &quot;XFUN&quot;, &quot;we&quot;, &quot;us&quot; or &quot;our&quot;.
              XFUN, as the data controller, determines the purposes and the means to process your information.
            </p>
          </div>

          <h2>CONTACT DETAILS</h2>
          <div className="normalText">
            <p>For inquiries, please contact us by sending an email to support@xfun.io.</p>
          </div>

          <h2>INFORMATION WE COLLECT</h2>
          <div className="normalText">
            <p>The personal information (&quot;Personal Data&quot;) about you that we collect includes:</p>

            <p>
              The information you provide to us during the account registration process for any of our products and
              services (&quot;Services&quot;) and any additional information you submit through your use of our
              Services, for example in forums, chat rooms, comment sections or in response to questionnaires and
              questionnaires.
            </p>

            <p>Your interactions with our customer service team (for example, chat, phone calls, emails).</p>

            <p>
              Details of your participation in our surveys or promotions and your communications through our Services.
            </p>

            <p>
              Device data you use to access and use the Services. For example, mobile network information, location
              data, IP address, browser type, hardware model, operating system and version, software and unique device
              identified.
            </p>

            <p>
              Your response to marketing campaigns, from us or from third parties, that is, when you open / click on
              such emails.
            </p>
          </div>

          <h2>HOW WE USE YOUR DATA</h2>
          <div className="normalText">
            <p>
              We use the information we collect from you to provide, maintain, protect and improve our Services, develop
              new ones and protect XFUN and yourself. We also use this information to provide you with personalized
              content, such as sending you more relevant promotional offers.
            </p>

            <p>We use your data to:</p>
            <ul>
              <li>Configure, validate and manage your account</li>
              <li>Improve customer service and training</li>
              <li>Verify your identity</li>
              <li>Process payment information</li>
              <li>Communicate account information and services</li>
              <li>Verify your age and the accuracy of your registration details</li>
              <li>
                Improve your experience with the website, offering products and services relevant to your interests.
                This is necessary for the purpose of our legitimate interests in delivering or presenting relevant
                content to our clients.
              </li>
              <li>
                Promote marketing and events (unless you decide to withdraw your consent to do so, which you can do
                through your account. Please allow up to 48 hours for changes you make to your marketing preferences to
                be fully processed).
              </li>
              <li>Evaluate and manage any risk for our business</li>
              <li>
                To investigate and diagnose the problems of our internal system, develop and improve our websites or
                applications and their functions.
              </li>
            </ul>

            <p>
              Other Purposes: We may be required to use and retain personal information to prevent loss and to protect
              our rights, privacy, security or property, or those of others in accordance with our legitimate interests.
            </p>

            <p>
              We may also collect information about you from third parties, such as our partners, service providers and
              publicly available websites, in order to comply with our legal and regulatory obligations. This keeps the
              data up-to-date and accurate, provides you with products and services relevant to your interests, and
              enhances your experience with our services.
            </p>
          </div>

          <h2>WITH WHOM WE SHARE YOUR INFORMATION</h2>
          <div className="normalText">
            <p>
              We may use the services offered by trusted third parties to process your information. These services
              include customer service, information technology, payments, sales, marketing, data analysis, research, and
              surveys.
            </p>

            <p>
              We may also share your personal information with the following organizations to comply with our legal and
              regulatory obligations:
            </p>
            <ul>
              <li>Law enforcement agencies, regulators and other authorities.</li>
              <li>Credit reference agencies.</li>
              <li>Fraud prevention agencies.</li>
              <li>Identity verification agencies.</li>
              <li>Sports governing bodies.</li>
              <li>Organizations that introduce you to us.</li>
              <li>Third parties that request (or allow us) to share their data with.</li>
            </ul>
          </div>

          <h2>RETENTION PERIOD</h2>
          <div className="normalText">
            <p>We will ensure that your personal data is protected and is only used for reasonable purposes.</p>

            <p>We will store your data as long as you remain a customer with us.</p>
            <p>
              If you cease to be a customer with us, we will retain your data for the minimum time required to fulfill
              any purpose set forth in this policy and any relevant legal or regulatory obligations.
            </p>
          </div>

          <h2>SECURITY</h2>
          <div className="normalText">
            <p>
              We understand that online security and data protection is a vital area for all of our customers, so it is
              important to us that you have confidence in the security of your personal data before registering an
              account. We are committed to employing security measures to protect your information from access by
              unauthorized persons and to prevent accidental or illegal processing, disclosure, destruction, loss,
              alteration, and damage. Our technology security solutions are highly advanced and governed by a mature
              framework.
            </p>
          </div>
        </Col>
        <Col xs={1} md={1} lg={2} />
      </Row>
    </div>
  );
};

export default Privacy;
