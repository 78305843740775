import './home.css';

import React from 'react';
import {Col, Row} from 'react-bootstrap';

import x_large from '../../images/x_large.svg';
import About from './About';
import DownloadApp from './DownloadApp';
import KeyFeatures from './KeyFeatures';

const Home: React.FC = () => {
    return (
    <div className="main d-flex row">
      <Row
        className="aboutSection"
        style={{
          backgroundImage: `url(${x_large})`,
          backgroundColor: '#f9f9f9',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% auto',
        }}>
        <Col xs={1} md={1} lg={2}></Col>
        <Col xs={10} md={10} lg={8}>
          <About />
        </Col>
        <Col xs={1} md={1} lg={2}></Col>
      </Row>
      <Row className="keyFeaturesSection">
        <Col xs={1} md={1} lg={2}></Col>
        <Col xs={10} md={10} lg={8}>
          <KeyFeatures />
        </Col>
        <Col xs={1} md={1} lg={2}></Col>
      </Row>
      <Row id="download" className="downloadSection">
        <Col xs={1} md={1} lg={2}></Col>
        <Col xs={10} md={10} lg={8}>
          <DownloadApp />
        </Col>
        <Col xs={1} md={1} lg={2}></Col>
      </Row>
    </div>
  );
};

export default Home;
