import './faqs.css';

import React from 'react';
import {Col, Row} from 'react-bootstrap';

import xw_android from '../images/xw_qr_android.jpg';
import xw_ios from '../images/xw_qr_ios.jpg';

const FAQ: React.FC = () => {
  return (
    <div className="mainFaqs">
      <Row className="mainFaqsWrapper">
        <Col xs={1} md={1} lg={2} />
        <Col xs={10} md={10} lg={8}>
          <p>&nbsp;</p>
          <h1>Getting Started</h1>
          <p>&nbsp;</p>
          <h2>Where can I download the XFUN Wallet?</h2>
          <div className="normalText">
            <p>
              Scan the QR code below or click on the desired OS to go directly to its store).
            </p>
            <Row>
              <Col xs={12} md={6} className="text-center">
                <p><img width={'100%'} style={{maxWidth: '300px'}} src={xw_ios}></img></p>
                <p><a href={'https://apps.apple.com/app/xfun-wallet/id1612225910'}>iOS XFUN Wallet</a></p>
              </Col>
              <Col xs={12} md={6} className="text-center">
                <p><img width={'100%'} style={{maxWidth: '300px'}} src={xw_android}></img></p>
                <p><a href={'https://play.google.com/store/apps/details?id=com.xfun.wallet&hl=en_US'}>Android XFUN Wallet</a></p>
              </Col>
            </Row>
          </div>
          <h2>What makes XFUN Wallet a self-custody wallet?</h2>
          <div className="normalText">
            <p>
              XFUN Wallet is designed to provide users with complete control over their assets and maintain a high level of security. One of the key features that make XFUN Wallet a self-custody wallet is the fact that only the wallet user has access to the passphrase that controls their assets.
            </p>
            <p>
              When you create a wallet, you generate a unique passphrase or mnemonic phrase. This passphrase acts as the key to your wallet and allows you to access and manage your assets. It is crucial to keep this passphrase secure and confidential, as it is the only way to control and authorize transactions from your wallet.
            </p>
            <p>
              By ensuring that only the wallet user has access to the passphrase, XFUN Wallet eliminates the need for third-party custodians or intermediaries. This means that you have full ownership and control over your assets, and there is no reliance on external entities to manage or safeguard your funds.
            </p>
            <p>
              With XFUN Wallet&apos;s self-custody model, you can confidently store, manage, and transact with your digital assets, knowing that you have the sole control and responsibility over your wallet and its contents.
            </p>
          </div>
          <h2>What is XFUN?</h2>
          <div className="normalText">
            <p>
              XFUN is a level 2 token on the Polygon side-chain, which is more performant and less expensive than Ethereum.
            </p>
            <p>
              FUN and XFUN are pegged at 1:1
            </p>
            <p>
              Leveraging the technologies of Polygon to make XFUN and in turn FUN more usable. For example, our proprietary bridge allows seamless exchanges between FUN and XFUN and gas-free XFUN transactions; eliminating the need for having the native token MATIC in your wallet.
            </p>
          </div>
          <h2>Which blockchains / networks / crypto assets does the wallet support?</h2>
          <div className="normalText">
            <p>
              Bitcoin (BTC), and all ERC-20 tokens on Ethereum and Polygon.
            </p>
          </div>
          <h2>Can I send crypto from any exchanges/marketplaces to XFUN Wallet?</h2>
          <div className="normalText">
            <p>
              Yes, you can send cryptocurrencies of the supported blockchains, Bitcoin (BTC), and all ERC-20 tokens on Ethereum and Polygon to the wallet.
            </p>
          </div>
          <h2>What information does XFUN Wallet have access to?</h2>
          <div className="normalText">
            <p>
              The wallet has access to your public wallet addresses and info about your device to customise your XFUN Wallet experience. We do not share your info with 3rd parties.
            </p>
          </div>
          <h2>What are private keys?</h2>
          <div className="normalText">
            <p>
              Private keys are vital components of the XFUN Wallet&apos;s security infrastructure. They are cryptographic codes that provide exclusive access to your digital assets stored within the XFUN Wallet. These unique and secret passwords act as a gateway, granting ownership and control over your XFUN and other supported cryptocurrencies. With your private keys, you can sign transactions within the XFUN Wallet, validating your ownership and authorizing the movement of funds. To ensure the utmost security, it is essential to keep your XFUN Wallet&apos;s private keys safeguarded and confidential. We recommend storing them offline in secure hardware wallets or encrypted digital storage solutions, minimizing the risk of unauthorized access or theft.
            </p>
          </div>
          <h2>What is a 12-word secret recovery phrase?</h2>
          <div className="normalText">
            <p>
              During the wallet setup process, you are provided with a 12-word passphrase, also known as a mnemonic phrase or seed phrase. This passphrase serves as a backup and allows you to restore your wallet anytime.
            </p>
            <p>
              To gain access to your wallet, simply install XFUN Wallet on a new device or reinstall it on your existing device. During the setup, select the option to &quot;Restore Wallet&quot; and enter your 12-word passphrase when prompted. XFUN Wallet will then use the passphrase to restore your wallet and retrieve your funds.
            </p>
            <p>
              It&apos;s crucial to keep your 12-word passphrase in a secure and confidential location because without it, you will not be able to access your assets. We recommend writing it down and storing it in a safe place, separate from your device. By doing so, you can ensure that even if you lose access to your wallet, your funds will remain safe and recoverable.
            </p>
          </div>
          <h2>What does my wallet&apos;s backup do?</h2>
          <div className="normalText">
            <p>
              The backup section of XFUN Wallet allows you to view your 12-word passphrase and backup this all important information somewhere safe and not connected to the Internet.
            </p>
          </div>
          <h2>Does XFUN Wallet have transaction fees to send or receive?</h2>
          <div className="normalText">
            <p>
              Most networks charge a transaction/gas fee for a transaction on the blockchains. One huge advantage of XFUN Wallet is that sending or exchanging XFUN on the Polygon network is gas free! XFUN takes care of that for its users using our proprietary decentralized bridge.
            </p>
          </div>
          <h2>How do I contact XFUN Wallet Support?</h2>
          <div className="normalText">
            <p>
              Email <a href={'mailTo:support@xfun.io'}>support@xfun.io</a> and we will take care of you.
            </p>
          </div>
          <p>&nbsp;</p>
          <h1>App Update</h1>
          <p>&nbsp;</p>
          <h2>Do I need to upgrade?</h2>
          <div className="normalText">
            <p>
              Yes, due to WalletConnect v1 shutting down on 28 June 2023 2 pm UTC, you need to upgrade to the latest version in order to continue to take advantage of connectivity with dApps using WalletConnect.
            </p>
            <p>
              Before you upgrade, please make sure you have recorded your 12-word passphrase for restoring your wallet and gaining access to your assets.
            </p>
          </div>
          <h2>Will my app update automatically to the new version?</h2>
          <div className="normalText">
            <p>
              This depends on your app store settings. In the cases that the app does not automatically update, go to the app store and download the latest (scan the QR code below or click on the desired OS to go directly to its store).
            </p>
            <Row>
              <Col xs={12} md={6} className="text-center">
                <p><img width={'100%'} style={{maxWidth: '300px'}} src={xw_ios}></img></p>
                <p><a href={'https://apps.apple.com/app/xfun-wallet/id1612225910'}>iOS XFUN Wallet</a></p>
              </Col>
              <Col xs={12} md={6} className="text-center">
                <p><img width={'100%'} style={{maxWidth: '300px'}} src={xw_android}></img></p>
                <p><a href={'https://play.google.com/store/apps/details?id=com.xfun.wallet&hl=en_US'}>Android XFUN Wallet</a></p>
              </Col>
            </Row>
          </div>
          <h2>How do I move my funds to the new wallet?</h2>
          <div className="normalText">
            <p>
              You can restore any wallet with XFUN Wallet and your assets will appear on your XFUN Wallet. Just go to Profile &gt; Security &gt; Restore Wallet and all you need is your 12-word secret passphrase.
            </p>
            <p>
              If you just (re)install the wallet, the app offers you the option to create a new wallet or restore an old one after the on-boarding screens.
            </p>
            <p>
              Alternatively, you can send your assets from another wallet to the XFUN Wallet by using the public address of your asset in the XFUN Wallet. You can use the Receive function of an asset on the home screen of your wallet.
            </p>
            <p>
              Please make sure the assets are supported by the XFUN Wallet (BTC and all ERC-20 tokens on Ethereum and Polygon blockchains).
            </p>
          </div>
          <h2>How can I restore my old wallet?</h2>
          <div className="normalText">
            <p>
              The first time you open the wallet, the app offers you the options to create a new wallet or restore an existing one. Or you can go to Profile &gt; Security &gt; Restore Wallet to load an old wallet to the XFUN Wallet. All you need is your 12-word passphrase.
            </p>
          </div>
          <h2>How do I recover my assets if I forget my secret phrase?</h2>
          <div className="normalText">
            <p>
              If your wallet is not already loaded to the XFUN Wallet or any wallets, you need your 12-word passphrase to recover your assets.
            </p>
            <p>
              Be sure to write down your passphrase and store it in a secure place not connected to the Internet. To back up your passphrase, go to Profile &gt; Security &gt; Backup.
            </p>
          </div>
          <h2>Where are my NFTs?</h2>
          <div className="normalText">
            <p>
              Your NFTs are still there! You just can’t see them in the XFUN Wallet … yet. We are working tirelessly to include this feature in the next update. In the meantime, if you need access to your NFTs, we recommend restoring your wallet/address with your 12-word passphrase to an NFT-compatible wallet.
            </p>
          </div>
          <p>&nbsp;</p>
          <h1>HOW-TOs</h1>
          <p>&nbsp;</p>
          <h2>How do I download the XFUN Wallet?</h2>
          <div className="normalText">
            <p>
              Scan the QR code below or click on the desired OS to go directly to its store).
            </p>
            <Row>
              <Col xs={12} md={6} className="text-center">
                <p><img width={'100%'} style={{maxWidth: '300px'}} src={xw_ios}></img></p>
                <p><a href={'https://apps.apple.com/app/xfun-wallet/id1612225910'}>iOS XFUN Wallet</a></p>
              </Col>
              <Col xs={12} md={6} className="text-center">
                <p><img width={'100%'} style={{maxWidth: '300px'}} src={xw_android}></img></p>
                <p><a href={'https://play.google.com/store/apps/details?id=com.xfun.wallet&hl=en_US'}>Android XFUN Wallet</a></p>
              </Col>
            </Row>
          </div>
          <h2>How do I install XFUN Wallet?</h2>
          <div className="normalText">
            <p>
              You can delete the wallet from your device at any time by going to Profile &gt; Security &gt; Delete Wallet. If you have assets on the wallet, please ensure that you have saved your 12-word passphrase with which you can restore the wallet with all your assets.
            </p>
          </div>
          <h2>What operating systems does XFUN Wallet support?</h2>
          <div className="normalText">
            <p>
              Android and iOS.
            </p>
          </div>
          <h2>How do I restore my wallet?</h2>
          <div className="normalText">
            <p>
              To regain access to your wallet, simply install XFUN Wallet on a new device or reinstall it on your existing device. During the setup, select the option to &quot;Restore Wallet&quot; and enter your 12-word passphrase when prompted. XFUN Wallet will then use the passphrase to restore your wallet and display your funds. Alternatively, you can go to Profile &gt; Security &gt; Restore Wallet to overwrite your existing wallet in the XFUN Wallet with a different one.
            </p>
          </div>
          <h2>How to back up my wallet?</h2>
          <div className="normalText">
            <p>
              You can back up your wallet by backing up your secret passphrase by going to Profile &gt; Security &gt; Backup, then write down your passphrase and store it in a secure place not connected to the Internet.
            </p>
            <p>
              DO NOT share your passphrase with anyone except personal people you completely trust. XFUN will never ask you to share your secret passphrase or any password information for that matter. Anyone with the passphrase can control your assets, so please be very vigilant.
            </p>
          </div>
          <h2>How to set a pin code?</h2>
          <div className="normalText">
            <p>
              Set up a passcode to keep your assets even safer by going to Profile &gt; Security &gt; Secure with Passcode
            </p>
          </div>
          <h2>What do I do if I forget my pin code?</h2>
          <div className="normalText">
            <p>
              If you forgot your wallet passcode, uninstall and reinstall the app, then restore your wallet with the 12-word passphrase.
            </p>
          </div>
          <h2>How to receive crypto assets to my XFUN Wallet?</h2>
          <div className="normalText">
            <p>
              Press &quot;Receive&quot; (inward/down arrow) on the wallet screen or on the detailed screen of your target cryptocurrency that you want to receive funds for. Copy the wallet address or scan the QR code with the wallet from which you want to send the funds.
            </p>
            <p>
              Make sure the asset is on one of the wallet supported blockchains: Bitcoin, Ethereum, and Polygon.
            </p>
          </div>
          <h2>How to send my crypto assets?</h2>
          <div className="normalText">
            <p>
              On the top of the wallet main screen, there are two buttons: Send and Receive, choose one of them to initiate a transaction.
            </p>
            <p>
              Select a token from your home screen, if it does not exist, scroll down to the bottom and press &quot;Manage Tokens&quot; to add the desired asset.
            </p>
            <p>
              Once you are inside the token screen, you can choose to send (the arrow on the left) or receive.
            </p>
          </div>
          <h2>How to add a token?</h2>
          <div className="normalText">
            <p>
              On the Wallet home screen, scroll down to the bottom and press &quot;Manage Tokens&quot; to add the desired asset. Make sure the asset is on one of the wallet supported blockchains: Bitcoin, Ethereum, and Polygon.
            </p>
          </div>
          <h2>How to connect my wallet to dApps?</h2>
          <div className="normalText">
            <p>
              Leveraging the WalletConnect technology, XFUN Wallet allows you to easily connect with a click of the Connect icon (bottom right) and scan of the dApp WalletConnect QR code, just like that, you are connected!
            </p>
            <p>
              You may connect to multiple dApps at the same time, click on the Connection List icon on the top left of the Connection screen, you can view and manage your existing connections.
            </p>
          </div>
          <h2>How do I secure my wallet with Face ID or Touch ID?</h2>
          <div className="normalText">
            <p>
              Look for these features in future releases of XFUN Wallet.
            </p>
          </div>
          <h2>How do I view my private keys?</h2>
          <div className="normalText">
            <p>
              Go to Profile &gt; Security &gt Show Private Keys
            </p>
            <p>
              Please note that Ethereum and Polygon have the same key.
            </p>
          </div>
          <h2>How to find my public wallet address?</h2>
          <div className="normalText">
            <p>
              Select a token on the target blockchain, choose to send, and the wallet address in text and in QR code are available for you to obtain your wallet’s address on the target network.
            </p>
          </div>
          <h2>How do I receive Bitcoin and other cryptocurrencies in XFUN Wallet?</h2>
          <div className="normalText">
            <p>
              Click on Receive (inward/down arrow) on the wallet screen or on the detailed screen of your target cryptocurrency for which you want to receive funds. Copy the wallet address or scan the QR code with the wallet from which you want to send the funds.
            </p>
            <p>
              You can receive cryptocurrencies of the supported blockchains, Bitcoin (BTC), and all ERC-20 tokens on Ethereum and Polygon.
            </p>
          </div>
          <h2>How do I send Bitcoin and other crypto out of XFUN Wallet?</h2>
          <div className="normalText">
            <p>
              Click Send (outward/up arrow) of the cryptocurrency you want to send to another supported wallet, enter the receiving wallet address, ensure that you send it to a supported Blockchain address. And confirm the transaction, then you are all set! The crypto should arrive at its destination after the block has been confirmed.
            </p>
          </div>
          <h2>How do I enable and disable assets in XFUN Wallet?</h2>
          <div className="normalText">
            <p>
              Go to PROFILE &gt; Assets, there you can add or remove tokens that appear in your wallet. Please note that this does not affect the actual balances of your assets; they remain in your wallet address on the blockchains, but your XFUN Wallet just does not show them.
            </p>
          </div>
          <h2>How can I tell which blockchain / network my token uses?</h2>
          <div className="normalText">
            <p>
              There is an abbreviated blockchain/network name next to each token name on the wallet/asset screen and in the asset detail screen.
            </p>
          </div>
          <h2>How do I find a transaction ID?</h2>
          <div className="normalText">
            <p>
              Go to Profile &gt; Transactions and locate the target transaction. From there, you can obtain the transaction ID or view the transaction in detail on the corresponding blockchain explorer.
            </p>
          </div>
          <h2>How do I log out of XFUN Wallet?</h2>
          <div className="normalText">
            <p>
              When you close the app, you will be prompted for your passcode the next time you open the wallet.
            </p>
          </div>
          <h2>How do I sync my XFUN Wallet to a different device?</h2>
          <div className="normalText">
            <p>
              Download the wallet to your other device and restore your wallet with your 12-word passphrase.
            </p>
          </div>
          <h2>How do I import a private key?</h2>
          <div className="normalText">
            <p>
              Your private keys are automatically imported when you restore your wallet with the 12-word passphrase.
            </p>
          </div>
          <h2>How do I customise my wallet?</h2>
          <div className="normalText">
            <p>
              You can choose the dark or light mode in your device’s app configuration section of your device. You can add other tokens to your wallet or disable them via “Manage Tokens” on the wallet screen.
            </p>
            <p>
              Profile &gt; Settings allows you to turn on and off your notifications, set your preferred networks, and rescan all the blockchain to refresh the data.
            </p>
            <p>
              Profile &gt; Security provides the ability to set up your passcode for added security, and also restore another wallet or delete the current wallet from the app.
            </p>
          </div>
          <h2>How do I manage multiple portfolios in the same wallet?</h2>
          <div className="normalText">
            <p>
              You can add all your ERC-20 tokens of the Ethereum and Polygon blockchains/networks to your XFUN Wallet.
            </p>
            <p>
              If you wish to manage multiple wallets with our app, look for this feature in our future releases.
            </p>
          </div>
          <h2>How do I export my transaction history?</h2>
          <div className="normalText">
            <p>
              To review your transaction history within the wallet, just go to PROFILE &gt; Transaction History. Alternatively, you can go to the desired blockchain explorer such as https://etherscan.io and search by your wallet address, you can then download your transaction history using the CSV Export function of the blockchain explorer site.
            </p>
          </div>
          <h2>Where do I get XFUN tokens?</h2>
          <div className="normalText">
            <p>
              Only through the XFUN Wallet, you can exchange FUN tokens for XFUN (pegged at 1:1).
            </p>
          </div>
          <h2>Where do I get FUN tokens?</h2>
          <div className="normalText">
            <p>
              You can get FUN tokens on several centralized exchanges such as Binance, MEXC, XT.com, Gate.io and Bitfinex with other cryptocurrencies or FIAT. And on decentralized exchanges with crypto such as ChangeNOW and Uniswap.
            </p>
          </div>
          <h2>How do I contact XFUN Wallet Support?</h2>
          <div className="normalText">
            <p>
              Email <a href={'mailTo:support@xfun.io'}>support@xfun.io</a> and we will take care of you.
            </p>
          </div>
          <p>&nbsp;</p>
          <h1>Troubleshooting</h1>
          <p>&nbsp;</p>
          <h2>Why can’t I see my tokens?</h2>
          <div className="normalText">
            <p>
              There are 3 possible reasons that you can’t see your tokens:
            </p>
            <ol>
              <li>Incomplete transactions<br></br>
                The tokens may not have arrived to your wallet yet. You can check on the corresponding blockchain explore with your wallet address.
              </li>
              <li>Unsupported chain<br></br>
                Your tokens may not be on one of the XFUN Wallet supported blockchains.
              </li>
              <li>Need to add token manually<br></br>
                Aside from the default tokens, you need to manually add other ERC-20 tokens of the Ethereum and Polygon blockchains.<br></br>
                You can manually add a token by going to the wallet home screen, scroll down to the bottom and press “Manage Tokens”, and then you can add a token from there.
              </li>
            </ol>
          </div>

          <h2>What happens if I lose access to my wallet?</h2>
          <div className="normalText">
            <p>
              With your 12-word passphrase, you can restore your wallet any time in XFUN Wallet.
            </p>
            <p>
              During the wallet setup process, you are provided with a 12-word passphrase, also known as a mnemonic phrase or seed phrase. This passphrase serves as a backup and allows you to restore your wallet anytime.
            </p>
            <p>
              To regain access to your wallet, simply install XFUN Wallet on a new device or reinstall it on your existing device. During the setup, select the option to “Restore Wallet” and enter your 12-word passphrase when prompted. XFUN Wallet will then use the passphrase to restore your wallet and display your funds.
            </p>
            <p>
              It&apos;s crucial to keep your 12-word passphrase in a secure and confidential location because without it, you will not be able to access your assets. We recommend writing it down and storing it in a safe place, separate from your device. By doing so, you can ensure that even if you lose access to your wallet, your funds will remain safe and recoverable.
            </p>
          </div>

          <h2>What should I do if I forget my password/passcode?</h2>
          <div className="normalText">
            <p>
              If you forgot your wallet passcode, uninstall and reinstall the app, then restore your wallet with the 12-word passphrase.
            </p>
          </div>
          <h2>I received an email from XFUN Wallet, asking me to provide my 12-word secret recovery phrase/password</h2>
          <div className="normalText">
            <p>
              XFUN does not have access to your email address, and we will NEVER ask you for your passcode or passphrase in any form
            </p>
            <b>
              Please always remember to back up your passcode and secret passphrase, but do not share with anyone that you do not want to have control of your assets.
            </b>
          </div>
          <p>&nbsp;</p>
          <h1>About Us</h1>
          <p>&nbsp;</p>
          <h2>How does XFUN Wallet make money?</h2>
          <div className="normalText">
            <p>
              XFUN Wallet operates on a different model compared to traditional wallets. Instead of profiting from your transactions, we focus on building a robust ecosystem around XFUN. We envision XFUN as a super utility token that can be utilized in various decentralized applications (dApps). By promoting the adoption and integration of XFUN in the broader dApp ecosystem, we create value and utility for the token.
            </p>
            <p>
              Additionally, we offer the XFUN API, enabling seamless integration for third-party dApps to connect with the XFUN community. This API facilitates the development of innovative applications within the casino space, fostering deeper decentralization and enhancing user experiences.
            </p>
            <p>
              Our revenue model revolves around the growth and utilization of XFUN as a thriving cryptocurrency within the dApp landscape, rather than profiting directly from user transactions. Our focus is on building a sustainable and community-driven ecosystem for the benefit of all XFUN holders and participants.
            </p>
          </div>
          <h2>Is XFUN Wallet open source?</h2>
          <div className="normalText">
            <p>
              XFUN Wallet is not currently an open-source project. However, we are committed to transparency and ensuring the security and integrity of our wallet. While the source code of XFUN Wallet is not publicly available for scrutiny, we have implemented rigorous security measures and best practices in its development.
            </p>
            <p>
              While we understand the value and benefits of open-source software, we have made the decision to prioritize the security of our users&apos; assets by not making the source code openly accessible at this time. Our aim is to provide a reliable and secure wallet experience that users can trust.
            </p>
          </div>
          <h2>Where do I get XFUN tokens?</h2>
          <div className="normalText">
            <p>
              Only through the XFUN Wallet, you can exchange FUN tokens for XFUN (pegged at 1:1).
            </p>
          </div>
          <h2>Where do I get FUN tokens?</h2>
          <div className="normalText">
            <p>
              You can get FUN tokens on several centralized exchanges such as Binance, MEXC, XT.com, Gate.io and Bitfinex with other cryptocurrencies or FIAT. And on decentralized exchanges with crypto such as ChangeNOW and Uniswap.
            </p>
          </div>
          <h2>What happens if XFUN Wallet goes out of business?</h2>
          <div className="normalText">
            <p>
              We are confident that’d never happen :) But as long as you have the 12-word passphrase, you can restore your wallet and regain access to your assets by restoring the wallet in other compatible apps.
            </p>
          </div>
          <p>&nbsp;</p>
          <h1>CONTACT OUR CUSTOMER CARE <a href={'mailTo:support@xfun.io'}>support@xfun.io</a></h1>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </Col>
        <Col xs={1} md={1} lg={2} />
      </Row>
    </div>
  );
};

export default FAQ;
