import './footer.css';

import React from 'react';
import {Link, useLocation} from 'react-router-dom';

import discordIcon from '../images/icon_discord.svg';
import telegramIcon from '../images/icon_telegram.svg';
import twitterIcon from '../images/icon_twitter.svg';
import * as route from '../pages/routesData';

const Footer: React.FC = () => {
  const location = useLocation();
  const date = new Date().getFullYear();

  return (
    <>
      {location.pathname === '/app-xfun/rubic.html' ? (
        <div></div>
      ) : (
        <footer className="d-flex footer align-items-center mt-auto">
          <div className="d-flex col justify-content-center">
            <a href="https://discord.com/invite/e7vfgKbEKU" target="_blank" rel="noreferrer">
              <img src={discordIcon} />
            </a>
            <a href="https://twitter.com/FUNtoken_io" target="_blank" rel="noreferrer">
              <img src={twitterIcon} />
            </a>
            <a href="https://t.me/officialFUNToken" target="_blank" rel="noreferrer">
              <img src={telegramIcon} />
            </a>
          </div>
          <div className="d-flex col justify-content-center">
            <Link title="Terms" target="_blank" to={route.TERMS} className="d-flex mx-2 footer-text">
              Terms
            </Link>
            <Link title="Privacy" target="_blank" to={route.PRIVACY} className="d-flex mx-2 footer-text">
              Privacy
            </Link>
            <Link title="FAQ" target="_blank" to={route.FAQ} className="d-flex mx-2 footer-text">
              FAQ
            </Link>
          </div>
          <div className="d-flex col justify-content-center footer-text">
            © {date} XFUN - operated by Funtech Ltd. All rights reserved.
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
