import React from 'react';

import app_store_badge from '../../images/app_store_badge.svg';
import play_store_badge from '../../images/play_store_badge.svg';

const DownloadApp: React.FC = () => {
  return (
    <div>
      <h4 className="subTitle">LET THE FUN BEGIN</h4>
      <h2>Download the app</h2>
      <div className="normalText downloadAppText">
        A multi-chain, non-custodial wallet that can swap between FUN and XFUN tokens
      </div>
      <div className="downloadAppImages">
        <a href="https://play.google.com/store/apps/details?id=com.xfun.wallet" target="_blank" rel="noreferrer">
          <img src={play_store_badge}></img>
        </a>
        <a href="https://apps.apple.com/app/xfun-wallet/id1612225910" target="_blank" rel="noreferrer">
          <img src={app_store_badge}></img>
        </a>
      </div>
    </div>
  );
};

export default DownloadApp;
